<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="900px">
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            <span class="headline">{{ title }} New Student</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    label="Class"
                    v-model="classes"
                    required
                    :rules="isValid.description"
                    readonly
                    disabled
                    v-uppercase
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-select
                    label="Select Stream"
                    :items="streams"
                    :item-text="'name'"
                    v-model="formData.schoolClassStream"
                    return-object
                    required
                    :rules="isValid.stream"
                    v-uppercase
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="FirstName"
                    v-model="formData.first_name"
                    required
                    :rules="isValid.firstName"
                    v-uppercase
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Middle Name"
                    v-model="formData.middle_name"
                    required
                    :rules="isValid.middleName"
                    v-uppercase
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    label="Surname"
                    v-model="formData.surname"
                    required
                    :rules="isValid.surname"
                    v-uppercase
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <DatePicker
                    v-model="formData.birth_date"
                    :label="'Birth Date'"
                    :maxDate="maxDate()"
                    :minDate="minDate()"
                    :yearPicker="true"
                  />
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-select
                    label="Gender"
                    :items="genders"
                    :item-text="'name'"
                    v-model="formData.gender"
                    return-object
                    required
                    :rules="isValid.gender"
                    v-uppercase
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <DialogButtons
            :close="close"
            :save="add"
            :loading="loading"
            :valid="valid"
            :title="title"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },

    closeDialog: {
      type: Function,
      required: true,
    },
    schoolClass: {
      type: Object,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
    genders: {
      type: Array,
      required: true,
    },
    streams: {
      type: Array,
      required: true,
    },
  },

  name: "SchoolClassesDialog",
  data: () => ({
    valid: true,
    isValid: {
      firstName: [
        (v) => !!v || " FirstName required",
        (v) => /.+[^#<>:;,?""*|/]+/.test(v) || "Invalid Character",
      ],
      middleName: [
        (v) => !!v || " MiddleName required",
        (v) => /.+[^#<>:;,?""*|/]+/.test(v) || "Invalid Character",
      ],
      surname: [
        (v) => !!v || " LastName required",
        (v) => /.+[^#<>:;,?""*|/]+/.test(v) || "Invalid Character",
      ],
      gender: [
        (v) => !!v || " you must select gender",
        (v) => /.+[^#<>:;,?""*|/]+/.test(v) || "Invalid Character",
      ],
      stream: [
        (v) => !!v || " you must select stream",
        (v) => /.+[^#<>:;,?""*|/]+/.test(v) || "Invalid Character",
      ],
      description: [(v) => !!v || " Name required"],
      level: [(v) => !!v || " Name required"],
    },
  }),
  methods: {
    add() {
      let data = { ...this.formData };

      data.schoolClass = this.schoolClass;
      data.academicYear = this.academicYear;

      this.save(data);
    },

    close() {
      this.closeDialog();
    },

    maxDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 3);
      return date.toISOString().substr(0, 10);
    },

    minDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 30);
      return date.toISOString().substr(0, 10);
    },
  },
  computed: {
    title() {
      return this.formData.id ? "Update" : "Add";
    },

    academicYear() {
      return this.$mixins.academicYear();
    },
    classes() {
      return this.schoolClass?.schoolClass?.name;
    },
  },
  mounted() {},
};
</script>
