<template>
  <div class="metadata-options">
    <!-- <pre>{{ schoolClass }}</pre> -->

    <v-card-actions class="pa-0">
      <page-title :title="title" />
      <v-spacer></v-spacer>
      <AddButton
        v-if="items.length > 0"
        :create-function="
          Object.keys(schoolClass).length > 0 ? openConfirmDialog : exit
        "
        :icon="'mdi-reload-alert'"
        :title="'RE-ASSIGN NUMBERs'"
        :disabled="Object.keys(schoolClass).length > 0 ? false : true"
      />
      <AddButton
        :create-function="
          Object.keys(schoolClass).length > 0 ? openDialog : exit
        "
        :icon="'mdi-view-grid-plus-outline'"
        :title="'REGISTER NEW STUDENT'"
        :disabled="Object.keys(schoolClass).length > 0 ? false : true"
      />
    </v-card-actions>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :value="selected"
        :server-items-length="params.total"
        :options.sync="pagination"
        :items-per-page="params.size"
        class="elevation-1"
        :no-data-text="'Data not available from this resource'"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 300, 500, -1],
        }"
      >
        <template v-slot:top>
          <v-card-title>
            <v-col cols="2" sm="12" md="2" class="pa-0">
              <v-select
                v-model="schoolClass"
                label="Select class"
                :items="schoolClassesLists"
                :item-text="'schoolClass.name'"
                @change="loadStudents($event)"
                @click:clear="loadStudents()"
                return-object
                required
                clearable
                hide-details
                autocomplete
              ></v-select>
            </v-col>
            <v-col cols="2" sm="12" md="2" class="pa-5">
              <v-select
                label="Filter by Stream"
                :items="streams"
                :item-text="'name'"
                @change="loadStudentsByStreams($event)"
                @click:clear="loadStudentsByStreams()"
                return-object
                required
                clearable
                hide-details
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="12" md="4" class="pa-0">
              <SearchField
                :search-function="searchOptions"
                :prepend-icon="'mdi-magnify'"
                :label="'Search Students'"
                :append-icon="''"
                v-if="items.length > 0"
              />
            </v-col>
          </v-card-title>
        </template>
        <template v-slot:item.actions="{ item }">
          <ActionsButtons
            :update-function="openDialog"
            :delete-function="openDeleteDialog"
            :formData="item"
            :updateIcon="' mdi-pencil-box-outline'"
            :deleteIcon="'mdi-trash-can-outline'"
            :updateButtonColor="''"
            :deleteButtonColor="''"
          />
        </template>
      </v-data-table>
    </v-card>

    <StudentsDialog
      :closeDialog="closeDialog"
      :isEditing="isEditing"
      :formData="formData"
      :dialog="dialog"
      :save="save"
      :loading="loading"
      :genders="genders"
      :schoolClass="schoolClass"
      :streams="streams"
    />
    <ConfirmDialog
      :reject-function="closeDeleteDialog"
      :accept-function="deleteObject"
      :item="item"
      :isOpen="isOpen"
      :title="'Are you sure you want to delete?'"
      :loading="loading"
    />

    <ConfirmDialog
      :reject-function="closeConfirmDialog"
      :accept-function="reAssignNumbers"
      :item="item"
      :isOpen="openConfirm"
      :title="'Are you sure you want Re-Assign Numbers ?'"
      :loading="loading"
    />
  </div>
</template>

<script>
import StudentsDialog from "./Forms/StudentsDialog.vue";
//import { get, create, update, remove } from "./services";
import * as student from "./services";
import { get as getClasses, streams } from "../../school-classes/services";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    StudentsDialog,
  },
  name: "Students",
  data() {
    return {
      selected: [],
      schoolClassesLists: [],
      schoolClass: {},
      title: "Manage Students",
      isEditing: false,
      formData: {},
      dialog: false,
      isOpen: false,
      loading: false,
      openConfirm: false,
      params: {},
      item: null,
      schoolClassStream: "",
      items: [],
      levels: [],
      streams: [],
      genders: [],
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
        },
        {
          text: "Exam Number",
          align: "start",
          sortable: false,
          value: "student_necta_serial",
          class: "primary--text",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "student.display_name",
          class: "primary--text",
        },

        {
          text: "Gender",
          align: "start",
          sortable: false,
          value: "student.gender.descriptions",
          class: "primary--text",
        },
        {
          text: "Class",
          align: "start",
          sortable: false,
          value: "schoolClass.name",
          class: "primary--text",
        },
        {
          text: "Stream",
          align: "start",
          sortable: false,
          value: "school_class_stream.name",
          class: "primary--text",
        },
        {
          text: "Year",
          align: "start",
          sortable: false,
          value: "academic_year.name",
          class: "primary--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "primary--text",
        },
      ],
    };
  },

  computed: {
    pagination: {
      get() {
        return this.params;
      },

      set(value) {
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;

        this.params.total = this.params.total;

        // this.init(this.params);
      },
    },

    academicYear() {
      return this.$mixins.academicYear();
    },
  },

  methods: {
    searchOption() {},
    closeConfirmDialog() {
      this.openConfirm = false;
    },
    init(params) {
      this.loading = true;
      student
        .get({
          ...params,
        })
        .then((response) => {
          this.loading = false;
          this.params.total = response.data[0].total;
          this.items = response.data[0]
            ? response.data[0].data
                .map((item) => ({
                  ...item,
                  schoolClassStream: item.school_class_stream,
                  schoolClass: item.school_class,
                  first_name: item.student.first_name,
                  middle_name: item.student.middle_name,
                  surname: item.student.surname,
                  gender: item.student.gender,
                  birth_date: item.student.birth_date,
                  fullName: item.student.display_name,
                }))

                .map((item, index) => ({
                  ...item,
                  index: ++index,
                }))
            : [];
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    exit() {
      return;
    },

    getSchoolClasses() {
      getClasses().then((response) => {
        this.schoolClassesLists = response.data[0]
          ? response.data[0].data
              .map((item) => ({
                ...item,
                schoolClass: item.school_class,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    loadStudents(data = null) {
      this.params.schoolClassId = data && data.id ? data.id : null;
      // this.params.schoolClassId = data && data.id ? data.class_id : null;

      this.init(this.params);
      this.loadStreams(data);

      if (data === null) {
        this.schoolClass = {};
      }
    },

    loadStreams(data) {
      this.params.schoolClassId = data && data.id ? data.id : null;
      // this.params.schoolClassId = data && data.id ? data.class_id : null;
      //this.loadStudentsByStreams(this.params);
      streams(this.params).then((response) => {
        //console.log("KKK", response.data[0]);
        this.streams = response.data[0]
          ? response.data[0]
              .map((item) => ({
                ...item,
              }))
              .sort(function(a, b) {
                return a.name > b.name ? 1 : -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    loadStudentsByStreams(data = null) {
      this.params.schoolClassStreamId = data && data.id ? data.id : null;
      this.init(this.params);
    },

    openDialog(data = null) {
      this.dialog = true;
      if (data && data.id) {
        this.formData = data;
      } else {
        this.formData.uuid = uuidv4();
      }
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.formData = {};
      //this.finishDataActions()
    },

    closeDeleteDialog() {
      this.item = null;
      this.isOpen = false;
    },

    openDeleteDialog(item) {
      this.isOpen = true;
      this.item = item;
    },

    deleteObject() {
      this.loading = true;
      student
        .remove(this.item)
        .then(() => {
          this.finishDataActions();
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
          }
        });
    },

    searchOptions(optionName) {
      (this.params.search = optionName ? optionName : null),
        this.init({
          size: this.params.size,
          total: this.params.total,
          ...this.params,
        });
    },

    save(data) {
      this.loading = true;
      if (data.id) {
        student
          .update(data)
          .then(() => {
            this.finishDataActions();
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      } else {
        student
          .create(data)
          .then(() => {
            console.log("created");
            this.finishDataActions();
            //this.init(this.params)
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      }
    },
    finishDataActions() {
      this.loading = false;
      this.isOpen = false;
      this.closeDialog();
      this.init(this.params);
    },

    getGenderList() {
      this.$store
        .dispatch("DataCategory/getCategoryOptions", "GENDER")
        .then((response) => {
          this.genders = response;
        });
    },

    reAssignNumbers() {
      let params = {
        ...this.params,
        academicYear: this.academicYear.id,
      };
      student.reAssignNumbers(params).then((response) => {
        this.openConfirm = false;
        this.init(this.params);
      });
    },

    openConfirmDialog() {
      this.openConfirm = true;
    },
  },

  watch: {
    pagination: {
      handler() {
        this.loading = true;
        // this.init(this.params);
      },
      update() {
        this.loading = true;
        //this.init(this.params);
      },
      deep: true,
    },
  },

  mounted() {
    this.getSchoolClasses();
    this.getGenderList();
  },
};
</script>

<style scoped></style>
